/**
 * crea parziali di handlebars
 */
Handlebars.registerPartial('sticker', document.getElementById('partial-sticker').innerHTML);

/**
 * permette di eseguire calcoli matematici nei template handlebars
 */
Handlebars.registerHelper("math", function(lvalue, operator, rvalue, options) {
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);

    return {
        "+": lvalue + rvalue,
        "-": lvalue - rvalue,
        "*": lvalue * rvalue,
        "/": lvalue / rvalue,
        "%": lvalue % rvalue
    }[operator];
});

Handlebars.registerHelper('if_eq', function(a, b, opts) {
    if (a == b) {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

/**
 * setto le variabili globali carrello e userID che verranno valorizzate al login dell'utente
 */
var cart = '';
var userID = '';
var userData = '';
var orderType = '';
var completed = false;

/**
 * funzioni che gestiscono il cambio sezione del sito
 */
var sectionInit = [];

showSection = function(section) {
    var sourceTemplate = document.getElementById('template-' + section).innerHTML;
    var template = Handlebars.compile(sourceTemplate);

    document.getElementById('section-main').innerHTML = template();

    startSection(section);

    sectionInit[section]();
};

startSection = function(section) {
    document.getElementById('section-main').setAttribute('data-section', section);
};