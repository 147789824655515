sectionInit['intro'] = function() {
    var logo = document.getElementsByClassName('logo');
    var introLeft = document.getElementsByClassName('intro__left');
    var boom = document.getElementsByClassName('intro__boom');
    var boomStars = document.getElementsByClassName('boom__stars');
    var boomText = document.getElementsByClassName('boom__text');
    var boomTextParticles = document.getElementsByClassName('boom__text-particles');
    var boomB = document.getElementsByClassName('boom__b');
    var boomO1 = document.getElementsByClassName('boom__o-1');
    var boomO2 = document.getElementsByClassName('boom__o-2');
    var boomM = document.getElementsByClassName('boom__m');
    var boomLines = document.getElementsByClassName('boom__lines');
    var explosion = document.getElementsByClassName('intro__explosion');

    showLogo = function() {
        logo[0].classList.toggle('logo_show');
    };

    showExplosion = function() {

        for (var i = 0; i < explosion.length; ++i) {
            explosion[i].classList.add('intro__explosion_show');
        }
    };

    showLogo();

    setTimeout(function() {
        explosion[0].classList.add('intro__explosion_show');

        setTimeout(function() {
            logo[0].classList.remove("logo_show");
            introLeft[0].style.left='0';
            explosion[1].classList.add('intro__explosion_show');
        }, 300);

        setTimeout(function() {
            explosion[2].classList.add('intro__explosion_show');
        }, 600);

        setTimeout(function() {
            explosion[3].classList.add('intro__explosion_show');
            boom[0].style.display='flex';
            boomText[0].classList.add('boom__text_show');
            boomTextParticles[0].classList.add('boom__text-particles_show');
            boomLines[0].classList.add('boom__lines_show');
        }, 900);

        setTimeout(function() {
            boomStars[0].classList.add('boom__stars_show');
        }, 920);

        setTimeout(function() {
            boomTextParticles[0].style.display='block';
            boomM[0].classList.add('explode');
            boomO1[0].classList.add('explode');
            boomO2[0].classList.add('explode');
            boomB[0].classList.add('explode');
        }, 2200);

        setTimeout(function() {
            showSection('access');
        }, 2800);

    }, 3500);
};