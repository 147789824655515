sectionInit['access'] = function() {
    var logo = document.getElementsByClassName('logo');
    document.getElementsByClassName('access')[0].classList.add('access_start');
    logo[0].classList.add('logo_show');

    setTimeout(function() {
        document.getElementsByClassName('section__angle_info')[0].classList.add('section__angle_info_start');
        document.getElementsByClassName('footer')[0].classList.add('footer_start');
        document.getElementsByClassName('footer')[1].classList.add('footer_start');
    }, 900);

    changePlaceholder = function () {
        if(window.innerWidth >= window.innerHeight) {
            document.getElementsByClassName('access__input')[0].setAttribute('placeholder', 'Inserisci qui il codice alfanumerico che hai trovato sul tuo Alboom');
        } else {
            document.getElementsByClassName('access__input')[0].setAttribute('placeholder', 'Inserisci il codice del tuo Alboom');
        }
    };

    document.querySelector("section[data-section='access']").addEventListener('resize', function() {
        changePlaceholder();
    }, true);

    changePlaceholder();

    document.querySelector("section[data-section='access']").addEventListener('keydown', function(event) {
        if (event.key === 'Enter') {
            event.preventDefault();

            loginAction();
        }
    });


    openCopiaincollaInfo = function(element) {
        // document.getElementById('modal-copiaincolla').innerHTML = element;
        document.getElementsByClassName('modal_copiaincolla')[0].classList.add('modal_open');
        dataLayer.push({
            event:'click',
            context_submitted: 'chi_e_copiaincolla',
        });
        setTimeout(function() {
            window.dataLayer.push(function() {
                this.reset();
            })
        },500);
    };

    closeCopiaincollaInfo = function() {
        document.getElementsByClassName('modal_copiaincolla')[0].classList.remove('modal_open')
    };


};
