sectionInit['album'] = function() {
    var logo = document.getElementsByClassName('logo');
    logo[0].classList.add('logo_show');
    /*
    ** 1 = portrait
    ** 2 = landscape
     */
    var deviceOrientation = null;

    var sourceTemplatePages = document.getElementById('template-pages').innerHTML;
    var templatePages = Handlebars.compile(sourceTemplatePages);
    var sourceTemplateCart = document.getElementById('template-cart').innerHTML;
    var templateCart = Handlebars.compile(sourceTemplateCart);
    var sourceTemplateRules = document.getElementById('template-rules').innerHTML;
    var templateRules = Handlebars.compile(sourceTemplateRules);
    var sourceTemplateAlboomCopy = document.getElementById('template-alboomCopy').innerHTML;
    var templateAlboomCopy = Handlebars.compile(sourceTemplateAlboomCopy);
    document.getElementById('modal-cart').innerHTML = templateCart();
    // document.getElementById('rules').innerHTML = templateRules();
    // document.getElementById('alboomCopy').innerHTML = templateAlboomCopy();

    /*
    ** rileva l'attuale orientamento dello schermo (o finestra)
    */
    detectOrientation = function() {
        if(window.innerWidth >= window.innerHeight){
            deviceOrientation = 2;
        }
        else {
            deviceOrientation = 1;
        }
    };

    /*
    ** controlla quali pagine mostrare quando cambia l'orientamento
    */
    setCurrentPageOnResize = function(oldOrientation) {

        if(deviceOrientation === 2 && oldOrientation === 1) {

            if (document.getElementById('album')) {

                var currentAlbum = document.getElementsByClassName('album__page');
                var pageNumber = (currentAlbum[0].getAttribute('data-pn')) - 6;

                var checkIfLeft_first = pageNumber % 2 === 0 ? 0 : 1;
                var checkIfLeft_second = pageNumber % 2 === 0 ? 2 : 1;

                if (pageNumber < 19) {
                    changePage((pageNumber - checkIfLeft_first), (pageNumber + checkIfLeft_second));
                }
                setElementsAttributes(cart);
            }
        }
    };

    /*
    ** aggiorna l'indicatore di figurine nel carrello
    */
    updateValueBox = function(number) {

        var anim = anime({
            targets: document.getElementById('total'),
            scale: [
                { value: 2 },
                { value: 1 }
            ],
            duration: 600,
            delay: 0,
            easing: 'easeInOutQuad'
        });

        document.getElementById('total').innerText = number;

        showElementsCompleteOrder();
    };

    /**
     * mostra/nasconde il bottone di completamento ordine
     */
    showElementsCompleteOrder = function() {

        if(!completed) {
            if(cart.length >= 1) {
                document.getElementById('button-complete-order').classList.remove('button_hide');
                document.getElementById('message-helper').classList.add('message-helper_hide');
            } else {
                document.getElementById('button-complete-order').classList.add('button_hide');
                document.getElementById('message-helper').classList.remove('message-helper_hide');
            }
        }
    };

    /*
    ** setta nel frontend le figurine già presenti nel carrello (quando il carrello viene letto dal db)
    */
    setElementsAttributes = function(cart) {
        var figurine = document.getElementsByClassName('sticker');

        for(var i = 0; i < figurine.length; i++) {
            var num = figurine[i].getAttribute('data-num');
            for(var j = 0; j < cart.length; j++) {
                if(cart[j].num === num) {
                    figurine[i].setAttribute('data-sel', '1');
                    break;
                }
            }
        }
    };

    /*
    ** viene chiamata quando si clicca su una figurina. Aggiorna il carrello e salva tutto nel db
    */
    figurineAction = function(el, cart) {

        if(!completed) {
            var numero = el.getAttribute('data-num');
            var sel = el.getAttribute('data-sel');

            if (sel === '0') {
                var figurina = {'num': numero};

                animationStickerAdding(el);
                cart.push(figurina);
                el.setAttribute('data-sel', '1');
                saveUserEvent('aggiunta figurina numero ' + numero);
            }
            else if (sel === '1') {
                for (var i = 0; i < cart.length; i++) {
                    if (cart[i].num === numero) {
                        cart.splice(i, 1);
                        break;
                    }
                }
                el.setAttribute('data-sel', '0');
                saveUserEvent('rimossa figurina numero ' + numero);
                updateValueBox(cart.length);
            }

            updateUserCart(JSON.stringify(cart));
        }
    };

    /*
    ** viene chiamata quando si clicca su un numero del carrello. Elimina il numero corrente e salva tutto nel db
    */
    removeFigurineCartAction = function(el, numero, cart) {

        var elParent = el.parentNode;
        var num = ('0' + numero).slice(-2);
        var sticker = document.querySelector("div[data-num='" + num + "']");

        elParent.removeChild(el);

        if (!elParent.hasChildNodes()) {
            elParent.parentNode.remove();
        }

        if (sticker) {
            sticker.setAttribute('data-sel', '0');
        }

        for (var i = 0; i < cart.length; i++) {
            if (cart[i].num === num) {
                cart.splice(i, 1);
                break;
            }
        }

        saveUserEvent('rimossa figurina numero ' + numero);
        updateValueBox(cart.length);
        updateUserCart(JSON.stringify(cart));

        if(cart.length === 0){
            document.getElementById('delete-all').style.display = 'none';
        }
    };

    /*
    ** viene chiamata quando si clicca su ELIMINA TUTTE nella modal carrello
    */
    removeAllFigurineCartAction = function() {

        cart = [];

        var figurine = document.getElementsByClassName('sticker');
        for(var i = 0; i < figurine.length; i++) {
            figurine[i].setAttribute('data-sel', '0');
        }

        saveUserEvent('rimosse tutte le figurine dal carrello');
        updateValueBox(cart.length);
        updateUserCart(JSON.stringify(cart));

        document.getElementById('delete-all').style.display = 'none';
        document.getElementById('cart').innerHTML = '';
    };

    animationStickerAdding = function(el) {
        var cloned = el.getElementsByClassName('sticker__number')[0].cloneNode(true);
        var clonedTop = el.getElementsByClassName('sticker__number')[0].getBoundingClientRect().top;
        var clonedLeft = el.getElementsByClassName('sticker__number')[0].getBoundingClientRect().left;
        var totalTop = document.getElementById('total').getBoundingClientRect().top;
        var totalLeft = document.getElementById('total').getBoundingClientRect().left;
        var transformX = totalLeft - clonedLeft;
        var transformY = clonedTop - totalTop;

        document.getElementsByTagName('body')[0].insertBefore(cloned, document.getElementsByTagName('body')[0].firstChild);
        document.getElementsByClassName('sticker__number')[0].style.top = clonedTop + 'px';
        document.getElementsByClassName('sticker__number')[0].style.left = clonedLeft + 'px';
        document.getElementsByClassName('sticker__number')[0].classList.add('sticker__number_start');

        var anim = anime({
            targets: document.getElementsByClassName('sticker__number')[0],
            translateX: transformX,
            translateY: -transformY,
            opacity: [
                { value: 1 },
                { value: 1 },
                { value: 0 }
            ],
            duration: 1000,
            delay: 0,
            easing: 'easeInOutQuad',
            complete: function(anim) {
                document.getElementsByClassName('sticker__number')[0].remove();

                updateValueBox(cart.length);
            }
        });
    };

    /*
    ** mostra la freccia dell'album passata come parametro
    */
    showArrow = function(element) {
        element.classList.remove('arrow_disabled');
    };

    /*
    ** nasconde la freccia dell'album passata come parametro
    */
    hideArrow = function(element) {
        element.classList.add('arrow_disabled');
    };

    /* ************************************************************************ */
    /* FUNZIONI CHE GESTISCONO IL CAMBIO PAGINA ALL'INTERNO DELLA SEZIONE ALBUM */
    /* ************************************************************************ */

    /*
    ** funzione chiamata quando si clicca sulla freccia 'indietro' dell'album per tornare alla pagina precedente
    */
    prevPage = function() {
        var currentAlbum = document.getElementsByClassName('album__page');

        var leftPageNumber = (currentAlbum[0].getAttribute('data-pn')) - 6;

        var minus = deviceOrientation === 2 ? 2 : 1;

        if (leftPageNumber <= minus) {
            hideArrow(document.getElementsByClassName('arrow')[0]);
        }

        if (leftPageNumber >= 19) {
            showArrow(document.getElementsByClassName('arrow')[1]);
        }

        if( leftPageNumber > 0 ) {
            changePage((leftPageNumber-minus), (leftPageNumber));
        }
    };

    /*
    ** funzione chiamata quando si clicca sulla freccia 'avanti' dell'album per andare alla pagina successiva
    */
    nextPage = function() {
        var currentAlbum = document.getElementsByClassName('album__page');

        var leftPageNumber = (currentAlbum[0].getAttribute('data-pn')) - 6;

        var plus1 = deviceOrientation === 2 ? 2 : 1;
        var plus2 = deviceOrientation === 2 ? 4 : 2;
        var limitPage = deviceOrientation === 2 ? 18 : 20;

        // console.log(leftPageNumber);

        if (leftPageNumber === 0) {
            showArrow(document.getElementsByClassName('arrow')[0]);
        }

        if (leftPageNumber === limitPage) {
            hideArrow(document.getElementsByClassName('arrow')[1]);
        }

        if (leftPageNumber <= limitPage)  {
            changePage((leftPageNumber+plus1), (leftPageNumber+plus2));
        }

    };

    /*
    ** estrae dall'array totale delle figurine solo quelle contenute nelle pagine in input (start, end) e renderizza la pagina dell'album
    */
    changePage = function(start, end) {

        var dataCurrent = {};

        dataCurrent.pages = dataAlbum.pages.slice(start, end);

        document.getElementById('album').classList.add('album_active');

        setTimeout(function() {
            document.getElementById('album-content').innerHTML = templatePages(dataCurrent);
            setElementsAttributes(cart);
        }, 750);

        setTimeout(function() {
            document.getElementById('album').classList.remove('album_active');
        }, 1500);

        // console.log('start: ' + start);
        // console.log('end: ' + end);

        var image = document.images[0];
        var downloadingImageOdd = new Image();
        var downloadingImageEven = new Image();

        if ((end+6) <= 20) {
            downloadingImageOdd.onload = function(){
                image.src = this.src;
            };
            downloadingImageEven.onload = function(){
                image.src = this.src;
            };
            downloadingImageEven.src = urlSite+'assets/images/album-pages/png/page-'+(end+6)+'.png';
            downloadingImageOdd.src = urlSite+'assets/images/album-pages/png/page-'+(end+7)+'.png';

            // console.log('end <= 27. end:' + (end+6));

        } else {
            // console.log('end > 27. end:' + (end+6));
        }

    };

    detectOrientation();
    updateValueBox(cart.length);
    changePage(0, deviceOrientation);
    setElementsAttributes(cart);

    /*
    ** evento chiamato per gestire le pagine correnti quando cambia l'orientamento.
    */
    window.addEventListener('resize', function() {
        var oldOr = deviceOrientation;

        detectOrientation();
        setCurrentPageOnResize(oldOr);
    }, true);

    /*
    ** evento chiamato per il cambio sezione.
    */
    endSectionAlbum = function(type) {

        orderType = type;

        document.getElementsByClassName('section__fade_album')[0].classList.add('section__fade_start');
        logo[0].classList.add('logo_hide');
        if (orderType == 1){
            dataLayer.push({
                event:'click',
            context_submitted: 'ordine_completo_inviato'
            });
            
            setTimeout(function() {
                window.dataLayer.push(function() {
                    this.reset();
                })
              },500);
        }
        setTimeout(function() {
            showSection('contacts');
        }, 500);
    };

    openNotice = function(element) {
        if (element === 'rules') {
            element = templateRules();
        } else if (element === 'alboomCopy') {
            element = templateAlboomCopy();
        }
        document.getElementById('notice-content').innerHTML = element;
        document.getElementsByClassName('notice')[0].classList.add('notice_open')
    };

    closeNotice = function() {
        document.getElementsByClassName('notice')[0].classList.remove('notice_open')
    };

    if(completed) {
        document.getElementById('hide-on-complete-left').style.display = 'none';
        document.getElementById('hide-on-complete-right').style.display = 'none';
        openNotice('alboomCopy');
    }
};
