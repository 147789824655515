sectionInit['instructions'] = function() {
    var logo = document.getElementsByClassName('logo');
    logo[0].classList.add('logo_show');

    document.getElementsByClassName('instructions')[0].classList.add('instructions_start');

    setTimeout(function() {
        document.getElementsByClassName('instructions')[0].classList.remove('instructions_start');
        document.getElementsByClassName('instructions')[0].classList.add('instructions_end');
        logo[0].classList.add('logo_hide');
    }, 5000);

    setTimeout(function() {
        showSection('album');
    }, 6800);
};
