var logo = document.getElementsByClassName('logo');
/*
** aggiorna il carrello nel db
*/
function updateUserCart(cartString) {
    var request = new XMLHttpRequest();
    request.open('POST', '/update-user-cart', true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    request.send("idUser="+userID+"&cart="+cartString);
}

/*
** salva nel db l'azione compiuta dall'utente
*/
function saveUserEvent(type) {
    var request = new XMLHttpRequest();
    request.open('POST', '/save-event', true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    request.send('idUser='+userID+'&type='+type);
}

/*
** renderizza il form contatti
*/
function getUserData() {
    var request = new XMLHttpRequest();
    request.open('GET', 'contacts-compile', true);

    request.onload = function() {

        if (request.status >= 200 && request.status < 400) {
            userData = JSON.parse(request.response);

            compileContactsForm();
        } else {

        }
    };

    request.send();
}

/*
** invia il form contatti
*/
function submitContactsForm(event) {
    event.preventDefault();

    showLoaderForm();

    var form = document.querySelectorAll('form[name="contact"]')[0];

    var request = new XMLHttpRequest();
    request.open('POST', '/contacts?orderType=' + orderType, true);
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    request.onload = function() {

        if (request.status >= 200 && request.status < 400) {
            var resp = request.responseText;

            if(resp === '1') {
                saveUserEvent('compilato il form contatti con successo');
                completed = true;
                dataLayer.push({
                  event:'click',
                  context_submitted: 'ordine_inviato'
                });
                
                setTimeout(function() {
                    window.dataLayer.push(function() {
                        this.reset();
                    })
                },500);

                showSection('outro');
            } else {
                hideLoaderForm();
                form.innerHTML = resp;
                saveUserEvent('compilato erratamente il form contatti');
            }
        } else {

        }
    };

    request.send(serialize(form));
}

/**
 * Trasforma in stringa i campi e i valori del form
 *
 * @param form
 * @returns {string}
 */
serialize = function(form) {
    var field;
    var s = [];

    if (typeof form === 'object' && form.nodeName === 'FORM') {

        for (i = 0; i < form.elements.length; i++) {

            field = form.elements[i];

            if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
                if (field.type === 'select-multiple') {
                    for (j = form.elements[i].options.length -1; j >= 0; j--) {
                        if(field.options[j].selected)
                            s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[j].value);
                    }
                } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                    s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value);
                }
            }
        }
    }

    return s.join('&').replace(/%20/g, '+').replace(/%5B/g, '[').replace(/%5D/g, ']');
};

/*
** viene chiamata quando viene inserito il codice di partecipazione. Cerca nel database il codice e logga l'utente
*/
loginAction = function() {
    var code = document.getElementById('code').value;
    var request = new XMLHttpRequest();
    request.open('POST', '/user-login', true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    document.getElementsByClassName('access__input')[0].classList.remove('access__input_shake');

    request.onload = function() {

        if (request.status >= 200 && request.status < 400) {
            var resp = request.responseText;

            if(resp === '0') {
                document.getElementsByClassName('access__input')[0].classList.add('access__input_shake');
            } else {
                var result = JSON.parse(resp);
                userID = result[0];
                cart = JSON.parse(result[1]);
                completed = result[2];

                saveUserEvent('eseguito l\'accesso tramite il codice');

                document.getElementsByClassName('access')[0].classList.remove('access_start');
                document.getElementsByClassName('access')[0].classList.add('access_end');
                document.getElementsByClassName('section__angle_info')[0].classList.remove('section__angle_info_start');
                document.getElementsByClassName('section__angle_info')[0].classList.add('section__angle_info_end');
                logo[0].classList.add('logo_hide');
                document.getElementsByClassName('footer')[0].classList.remove('footer_start');
                document.getElementsByClassName('footer')[1].classList.remove('footer_start');
                document.getElementsByClassName('footer')[0].classList.add('footer_end');
                document.getElementsByClassName('footer')[1].classList.add('footer_end');


                setTimeout(function() {
                    showSection('instructions');
                }, 2000);
                dataLayer.push({
                  event:'click',
                  context_submitted: 'accesso_effettuato'
                });
                setTimeout(function() {
                    window.dataLayer.push(function() {
                        this.reset();
                    })
                },500);
                
            }
        } else {
            console.log('error!');
        }
    };
    request.send('code='+code);
};

oneMoreAlbumAction = function() {
    var sourceTemplateAlboomCopySuccess = document.getElementById('template-alboomCopySuccess').innerHTML;
    var templateAlboomCopySuccess = Handlebars.compile(sourceTemplateAlboomCopySuccess);
    document.getElementById('notice-content').innerHTML = templateAlboomCopySuccess();

    saveUserEvent('richiesto un nuovo album');

    var request = new XMLHttpRequest();
    request.open('GET', '/one-more-album', true);
    request.send();
};
