var logo = document.getElementsByClassName('logo');

/**
 * crea la lista delle figurine da mostrare nella modale
 */
openModal = function() {
    compileModal();
    document.getElementsByClassName('modal')[0].classList.add('modal_open');
    logo[1].classList.add('logo_show');
    logo[1].classList.add('logo_lal-rose');
};

closeModal = function() {
    document.getElementsByClassName('modal')[0].classList.remove('modal_open');
    logo[1].classList.remove('logo_show');
};

compileModal = function() {
    var modal = document.getElementById('cart');
    modal.innerHTML = '';
    var checkMinOne = 0;

    for(var i = 6; i < (dataAlbum.pages.length) + 6; i++) {

        var stickersInThisPage = '';

        for(var k = 0; k < dataAlbum.pages[i-6][1].length; k++) {

            for(var j = 0; j < cart.length; j++) {

                if (dataAlbum.pages[i - 6][1][k].number === cart[j].num) {
                    checkMinOne = 1;
                    stickersInThisPage = stickersInThisPage + '<a href="javascript:void(0);" class="cart__number c-rose Fz(23px) Td(n)" onclick="removeFigurineCartAction(this, '+ cart[j].num +', cart);"><strong>' + cart[j].num + '</strong></a>';
                    break;
                }

            }

        }

        if(stickersInThisPage !== '') {
            modal.innerHTML = modal.innerHTML + '<li class="cart__item Fz(12px)"><div class="cart__page">Pagina ' + i + '</div><div class="cart__numbers-group">' + stickersInThisPage + '</div></li>';
        }
    }

    if(checkMinOne !== 0){
        document.getElementById('delete-all').style.display = 'inline-block';
    }
};
