sectionInit['contacts'] = function() {
    var logo = document.getElementsByClassName('logo');
    var sectionMain = document.getElementById('section-main');

    logo[0].classList.add('logo_show');
    logo[0].classList.add('logo_frame-rose');

    document.getElementsByClassName('contacts')[0].classList.add('contacts_start');

    getUserData();

    compileContactsForm = function() {
        document.getElementById('contact_name').value = userData['name'];
        document.getElementById('contact_last_name').value = userData['last_name'];
        document.getElementById('contact_company').value = userData['company'];
        document.getElementById('contact_address').value = userData['address'];
        document.getElementById('contact_cap').value = userData['cap'];
        document.getElementById('contact_city').value = userData['city'];
        document.getElementById('contact_province').value = userData['province'];
        document.getElementById('contact_e_mail').value = userData['email'];

        document.getElementById('user-name').innerText = userData['name'];
    };

    /**
     * Gestisce il cambio nome sul change del campo input
     */
    document.getElementById('contact_name').oninput = function() {
        document.getElementById('user-name').innerText = document.getElementById('contact_name').value;
    };

    showLoaderForm = function() {
        document.getElementById('form-wrapper').classList.add('hide');
        document.getElementById('form-boom').classList.add('show');
    };

    hideLoaderForm = function() {
        document.getElementById('form-wrapper').classList.remove('hide');
        document.getElementById('form-boom').classList.remove('show');
    };

    goBack = function() {
        document.getElementsByClassName('contacts')[0].classList.add('contacts_end');
        sectionMain.classList.add('section_back');
        logo[0].classList.add('logo_hide');

        setTimeout(function() {
            showSection('album');
            sectionMain.classList.remove('section_back');
        }, 1800);
    }
};
