sectionInit['outro'] = function() {
    var boom = document.getElementsByClassName('outro__boom');
    var boomStars = document.getElementsByClassName('boom__stars');
    var boomLines = document.getElementsByClassName('boom__lines');
    var boomText = document.getElementsByClassName('boom__text');


    setTimeout(function() {
        boom[0].style.display='flex';
        boomText[0].classList.add('boom__text_show');
        boomLines[0].classList.add('boom__lines_show');
        document.getElementsByClassName('outro__text')[0].classList.add('outro__text_start');
    }, 900);

    setTimeout(function() {
        boomStars[0].classList.add('boom__stars_show');
    }, 920);

    setTimeout(function() {
        document.getElementsByClassName('footer')[0].classList.add('footer_start');
    }, 1000);
};
